define('ember-singularity-mixins/mixins/resize-handler', ['exports', 'ember'], function (exports, _ember) {
  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var RESIZE = 'resize';

  exports['default'] = _ember['default'].Mixin.create((_Ember$Mixin$create = {
    unifiedEventHandler: _ember['default'].inject.service()

  }, _defineProperty(_Ember$Mixin$create, RESIZE, undefined), _defineProperty(_Ember$Mixin$create, 'resizeOnInsert', true), _defineProperty(_Ember$Mixin$create, 'registerResizeHandlers', _ember['default'].on('didInsertElement', function () {
    // Bind 'this' context to the resize handler for when passed as a callback
    var resize = this.get(RESIZE).bind(this);
    this.set(RESIZE, resize);

    this.get('unifiedEventHandler').register('window', RESIZE, resize);

    this._resizeHandlerRegistered = true;

    if (this.get('resizeOnInsert')) {
      // Call the resize handler to make sure everything is in the correct state.
      // We do it after the current render, to avoid any side-effects.
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        resize();
      });
    }
  })), _defineProperty(_Ember$Mixin$create, 'unregisterResizeHandlers', _ember['default'].on('willDestroyElement', function () {
    if (this._resizeHandlerRegistered) {
      var resize = this.get(RESIZE);
      this.get('unifiedEventHandler').unregister('window', RESIZE, resize);
      this._resizeHandlerRegistered = false;
    }
  })), _Ember$Mixin$create));
});
/**
 * The resize-handler mixin adds an easy-to-use "resize" hook, similar to the
 * default Ember hook for click(). It is only applicable to views/components.
 */
// The hook for your resize functionality, you must implement this

// Determines if we should fire a resize event on element insertion

// Setups up the handler binding for the resize function

// Unbinds the event handler on destruction of the view