define('ember-read-more/components/read-more', ['exports', 'ember', 'ember-read-more/templates/components/read-more'], function (exports, _ember, _emberReadMoreTemplatesComponentsReadMore) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberReadMoreTemplatesComponentsReadMore['default'],
    classNames: 'read-more',
    classNameBindings: ['isOpen:is-open:is-closed'],

    isOpen: false,
    maxHeight: '200px',

    toggleClass: 'read-more-toggle',
    openText: 'Read more',
    closeText: 'Close',
    toggleText: _ember['default'].computed('openText', 'closeText', 'isOpen', function () {
      return this.get('isOpen') ? this.get('closeText') : this.get('openText');
    }),

    bodyClass: 'read-more-body',
    bodyCss: 'overflow-y: hidden; width: 100%; display: block;',
    bodyStyle: _ember['default'].computed('isOpen', 'maxHeight', 'bodyCss', function () {
      var styles = this.get('bodyCss');
      if (!this.get('isOpen')) {
        styles += 'max-height: ' + this.get('maxHeight') + ';';
      }
      return new _ember['default'].Handlebars.SafeString(styles);
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');

        if (this.get('isOpen')) {
          this.sendAction('onOpen');
        } else {
          this.sendAction('onClose');
        }
      }
    }
  });
});