define('ember-component-css/initializers/component-styles', ['exports', 'ember', 'ember-component-css/pod-names', 'ember-getowner-polyfill'], function (exports, _ember, _emberComponentCssPodNames, _emberGetownerPolyfill) {
  exports.initialize = initialize;
  var Component = _ember['default'].Component;
  var ComponentLookup = _ember['default'].ComponentLookup;
  var computed = _ember['default'].computed;

  ComponentLookup.reopen({
    componentFor: function componentFor(name, owner) {
      owner = Boolean(owner.hasRegistration) ? owner : (0, _emberGetownerPolyfill['default'])(this);

      if (_emberComponentCssPodNames['default'][name] && !owner.hasRegistration('component:' + name)) {
        owner.register('component:' + name, Component);
      }
      return this._super.apply(this, arguments);
    }
  });

  Component.reopen({
    _componentIdentifier: computed({
      get: function get() {
        return (this._debugContainerKey || '').replace('component:', '');
      }
    }),

    componentCssClassName: computed({
      get: function get() {
        return _emberComponentCssPodNames['default'][this.get('_componentIdentifier')] || '';
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var name = this.get('componentCssClassName');

      if (this.get('tagName') !== '' && name) {
        this.classNames.push(name);
      }
    }
  });

  function initialize() {}

  exports['default'] = {
    name: 'component-styles',
    initialize: initialize
  };
});