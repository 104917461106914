define('ember-keyboard/utils/get-cmd-key', ['exports'], function (exports) {
  exports['default'] = translateCmd;

  function translateCmd() {
    var platform = arguments.length <= 0 || arguments[0] === undefined ? navigator.platform : arguments[0];

    if (platform.indexOf('Mac') > -1) {
      return 'meta';
    } else {
      return 'ctrl';
    }
  }
});