define('ember-cli-showdown/components/markdown-to-html', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var merge = _ember['default'].merge;
  var getOwner = _ember['default'].getOwner;

  var CONFIG_LOOKUP = 'config:environment';

  var assign = _ember['default'].assign;

  if (!assign) {
    assign = function assignPolyfill() {
      for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
        objects[_key] = arguments[_key];
      }

      return objects.reduce(merge);
    };
  }

  var ShowdownComponent = _ember['default'].Component.extend({
    layout: _ember['default'].HTMLBars.template((function () {
      return {
        meta: {
          'revision': 'Ember@2.8.3',
          'loc': {
            'source': null,
            'start': {
              'line': 1,
              'column': 0
            },
            'end': {
              'line': 1,
              'column': 8
            }
          }
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment('');
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [['content', 'html', ['loc', [null, [1, 0], [1, 8]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })()),
    markdown: '',
    _globalOptions: null,

    extensions: computed(function () {
      return [];
    }),

    defaultOptionKeys: computed(function () {
      return Object.keys(showdown.getDefaultOptions());
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var owner = getOwner(this);

      if (owner && owner.hasRegistration(CONFIG_LOOKUP)) {
        this._globalOptions = (owner.resolveRegistration(CONFIG_LOOKUP) || {}).showdown;
      }
    },

    html: computed('markdown', 'converter', function () {
      var showdownOptions = this.getShowdownProperties(get(this, 'defaultOptionKeys'));
      var converter = get(this, 'converter');

      for (var option in showdownOptions) {
        if (showdownOptions.hasOwnProperty(option) && typeof showdownOptions[option] !== 'undefined') {
          converter.setOption(option, showdownOptions[option]);
        }
      }

      return _ember['default'].String.htmlSafe(converter.makeHtml(get(this, 'markdown')));
    }).readOnly(),

    converter: computed('extensions', function () {
      var extensions = get(this, 'extensions');

      if (typeof extensions === 'string') {
        extensions = extensions.split(' ');
      }

      return new showdown.Converter({ extensions: extensions });
    }),

    getShowdownProperties: function getShowdownProperties(keyNames) {
      var _this = this;

      return keyNames.reduce(function (accumulator, keyName) {
        var value = get(_this, keyName);

        if (value === undefined && _this._globalOptions) {
          value = get(_this._globalOptions, keyName);
        }

        accumulator[keyName] = value;

        return accumulator;
      }, {});
    }
  });

  ShowdownComponent.reopenClass({
    positionalParams: ['markdown']
  });

  exports['default'] = ShowdownComponent;
});
/* global showdown */