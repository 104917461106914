define('ember-changeset-validations/validators/format', ['exports', 'ember', 'ember-changeset-validations/utils/validation-errors'], function (exports, _ember, _emberChangesetValidationsUtilsValidationErrors) {
  exports['default'] = validateFormat;
  var isEmpty = _ember['default'].isEmpty;
  var assert = _ember['default'].assert;
  var typeOf = _ember['default'].typeOf;

  var regularExpressions = {
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    phone: /^([\+]?1\s*[-\/\.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-\/\.]?\s*(\d{3})\s*[-\/\.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[\.]?|extension)\s*([#*\d]+))*$/,
    url: /(?:([A-Za-z]+):)?(\/{0,3})[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-{}]*[\w@?^=%&amp;\/~+#-{}])??/
  };

  function validateFormat() {
    var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var allowBlank = options.allowBlank;
    var type = options.type;
    var regex = options.regex;
    var _options$inverse = options.inverse;
    var inverse = _options$inverse === undefined ? false : _options$inverse;

    assert('inverse options should be a boolean', typeOf(inverse) === 'boolean');

    return function (key, value) {
      if (allowBlank && isEmpty(value)) {
        return true;
      }

      if (!regex && type) {
        regex = regularExpressions[type];
      }

      if (regex && regex.test(value) === inverse) {
        if (type && !inverse) {
          return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, type, value, options);
        }
        return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'invalid', value, options);
      }

      return true;
    };
  }
});
/**
 * For code taken from ember-cp-validations
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */