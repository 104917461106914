define('ember-changeset-validations/validators/length', ['exports', 'ember', 'ember-changeset-validations/utils/validation-errors'], function (exports, _ember, _emberChangesetValidationsUtilsValidationErrors) {
  exports['default'] = validateLength;
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var isNone = _ember['default'].isNone;
  var typeOf = _ember['default'].typeOf;

  function validateLength() {
    var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var allowBlank = options.allowBlank;
    var is = options.is;
    var min = options.min;
    var max = options.max;

    return function (key, value) {
      if (allowBlank && isEmpty(value)) {
        return true;
      }

      if (isNone(value)) {
        return (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'invalid', value, options);
      }

      var length = get(value, 'length');

      if (isPresent(is) && typeOf(is) === 'number') {
        return length === is || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'wrongLength', value, options);
      }

      if (isPresent(min) && isPresent(max)) {
        return length >= min && length <= max || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'between', value, options);
      }

      if (isPresent(min) && isEmpty(max)) {
        return length >= min || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'tooShort', value, options);
      }

      if (isPresent(max) && isEmpty(min)) {
        return length <= max || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'tooLong', value, options);
      }

      return true;
    };
  }
});
/**
 * For code taken from ember-cp-validations
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */