define('ember-changeset/utils/includes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = includes /*:: <T> */;
  function includes(arr /*: Array<T> */
  ) /*: Array<T> */
  /*: boolean */{
    (true && !(Ember.typeOf(arr) === 'array') && Ember.assert('must be array', Ember.typeOf(arr) === 'array'));

    var wrapped = Ember.A(arr);
    var inclusionFn = wrapped.includes || wrapped.contains;

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return inclusionFn.apply(arr, args);
  }
});